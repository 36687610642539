import axios from "axios";

const url = process.env.REACT_APP_BASE_URL + "/api/auth" || "";
console.log("kjasadkalsdjkasjd", url);
export const login = async (email, password) => {
  const response = await axios.post(`${url}/login`, {
    email,
    password,
  });
  return response;
};
