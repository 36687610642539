import React from "react";
import { NavLink, Navigate, Outlet } from "react-router-dom";

const DashboardLayout = () => {
  const isAuthenticated = localStorage.getItem("authToken"); // Check if the token exists

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  const logout = () => {
    localStorage.removeItem("authToken");
    return <Navigate to="/" replace />;
  };

  return (
    <div className="container-fluid">
      <div className="row">
        {/* Sidebar */}
        <div className="col-2 bg-light vh-100 p-3 justify-content-between align-items-baseline d-flex flex-column">
          <nav className="nav flex-column mt-5">
            <NavLink
              className="nav-link text-dark fs-4"
              to="/dashboard/inquiry"
            >
              Inquiry
            </NavLink>
            <NavLink
              className="nav-link text-dark fs-4"
              to="/dashboard/bookings"
            >
              Bookings
            </NavLink>
            <NavLink
              className="nav-link text-dark fs-4"
              to="/dashboard/vrc-payment"
            >
              Vrc Payment
            </NavLink>
            <NavLink
              className="nav-link text-dark fs-4"
              to="/dashboard/update-password"
            >
              Update Password
            </NavLink>
          </nav>
          <button className="btn btn-danger mx-auto" onClick={logout}>
            Logout
          </button>
        </div>

        {/* Main Content */}
        <div className="col-10 p-4">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
