import React, { useEffect, useState } from "react";
import TopBar from "./compenent/TopBar";
import ShowPayment from "./compenent/ShowPayment";
import plotData from "../../data/property.plots.json";

const VrcPayment = () => {
  const [refresh, setRefresh] = useState(false);
  const [showModal, setShowModal] = useState(false);
  console.log("show", showModal);

  const [formData, setFormData] = useState({
    marla: null,
    payment_plans: {
      duration: "5",
      down_payment: null,
      confirmation: null,
      monthly_installment: null,
      semi_annual_installment: null,
      possession: null,
      total_price: null,
    },
  });

  useEffect(() => {
    if (!showModal) {
      setFormData({
        marla: null,
        payment_plans: {
          duration: "5",
          down_payment: null,
          confirmation: null,
          monthly_installment: null,
          semi_annual_installment: null,
          possession: null,
          total_price: null,
        },
      });
      setShowModal(false);
    }
  }, [showModal]);

    const handleGetPrice = async () => {
    try {
      const price = sessionStorage.getItem("vrcprice");
      if (price) {
        return price;
      }
      const response = await fetch(
        "https://api.coingecko.com/api/v3/simple/price?ids=virtual-coin&vs_currencies=pkr"
      );
      const data = await response.json();
      return data["virtual-coin"].pkr;
    } catch (error) {
      alert(`Error: ${error}`);
    }
  };

  const calculateAdjustedValue = (value, price) => (value / price) * 1.3; // Add 30% increase to all values

  const calculateAutoPrice = async (marla) => {
    if (parseInt(marla) === 3 || parseInt(marla) === 4 || parseInt(marla) === 5 || parseInt(marla) === 10) {
      const price = await handleGetPrice();

      const data = plotData.filter((item) => parseInt(item.marla) === parseInt(marla))[0];
      setFormData({
        marla: data.marla,
        payment_plans: {
          duration: data.payment_plans.duration,
          down_payment: calculateAdjustedValue(data.payment_plans.down_payment, price).toFixed(2),
          confirmation: calculateAdjustedValue(data.payment_plans.confirmation, price).toFixed(2),
          monthly_installment: calculateAdjustedValue(data.payment_plans.monthly_installment, price).toFixed(2),
          semi_annual_installment: calculateAdjustedValue(data.payment_plans.semi_annual_installment, price).toFixed(2),
          possession: calculateAdjustedValue(data.payment_plans.possession, price).toFixed(2),
          total_price: calculateAdjustedValue(data.payment_plans.total_price, price).toFixed(2)
   
        },
      });
    } else {
        return;
    }
  }

  useEffect(() => {
    if (formData.marla) {
      calculateAutoPrice(formData.marla);
    }
  }, [formData.marla])

  return (
    <div>
      <TopBar
        refresh={refresh}
        setRefresh={setRefresh}
        formData={formData}
        setFormData={setFormData}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <ShowPayment
        refresh={refresh}
        setFormData={setFormData}
        setShowModal={setShowModal}
      />
    </div>
  );
};

export default VrcPayment;
