import React, { useEffect, useState } from "react";
import { APIinstance } from "../../axios.config";
import ConfirmationModal from "../../common/modal/ConfirmationModal";

import { saveAs } from "file-saver";

const Home = () => {
  const [inquiries, setInquiries] = useState([]);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);

  useEffect(() => {
    const fetchInquiries = async () => {
      try {
        const response = await APIinstance.get("/api/inquiry");
        console.log("responseresponseresponse", response);
        if (response.data.success) {
          setInquiries(response.data.data);
        } else {
          setError("Failed to fetch inquiries");
        }
      } catch (err) {
        setError("An error occurred while fetching data");
      }
    };

    fetchInquiries();
  }, []);

  const markAsContacted = async () => {
    if (!selectedBooking) return;

    try {
      const response = await APIinstance.get(
        `/api/inquiry/${selectedBooking._id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        }
      );

      if (response.data.success) {
        setInquiries((prevBookings) =>
          prevBookings.map((booking) =>
            booking._id === selectedBooking._id
              ? { ...booking, contacted: true }
              : booking
          )
        );
        setShowModal(false);
        setSelectedBooking(null);
      } else {
        alert("Failed to update booking status");
      }
    } catch (err) {
      console.error("Error updating contacted status:", err);
      alert("An error occurred while updating the booking status");
    }
  };

  const handleShowModal = (booking) => {
    setSelectedBooking(booking);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedBooking(null);
  };

  const exportData = async () => {
    try {
      const response = await APIinstance.post(
        "/api/bookings/export",
        {},
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        }
      );

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      saveAs(blob, "data.xlsx");
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  return (
    <>
      <div className="container mt-5">
        <h1 className="text-center mb-4">Inquiry List</h1>

        {error && <div className="alert alert-danger">{error}</div>}
        <button className="btn btn-primary" onClick={exportData}>
          exportData
        </button>
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Full Name</th>
              <th>Mobile Number</th>
              <th>Email</th>
              <th>Interested Area</th>
              <th>Preferred Area</th>
              <th>Created At</th>
              <th>Contacted</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {inquiries.length > 0 ? (
              inquiries.map((inquiry, index) => (
                <tr key={inquiry._id}>
                  <td>{index + 1}</td>
                  <td>{inquiry.fullName}</td>
                  <td>{inquiry.mobileNumber}</td>
                  <td>{inquiry.email}</td>
                  <td>{inquiry.interestedArea}</td>
                  <td>{inquiry.preferredArea}</td>
                  <td>{new Date(inquiry.createdAt).toLocaleString()}</td>
                  <td>{!!inquiry.contacted ? "Contacted" : "Not Contacted"}</td>
                  <td>
                    {!!inquiry.contacted ? (
                      <button className="btn btn-secondary" disabled>
                        Contacted
                      </button>
                    ) : (
                      <button
                        className="btn btn-success"
                        onClick={() => handleShowModal(inquiry)}
                      >
                        Mark as Contacted
                      </button>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="text-center">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ConfirmationModal
        showConfirmModal={showModal}
        closeConfirmModal={handleCloseModal}
        handleDelete={markAsContacted}
        modalTitle="Confirm Contact"
        message="Are you sure you want to mark it as contracted?"
        button="Confirm"
      />
    </>
  );
};

export default Home;
