import React from "react";
import AddMethod from "./AddMethod";

const TopBar = ({
  formData,
  setFormData,
  refresh,
  setRefresh,
  showModal,
  setShowModal,
}) => {
  return (
    <div className="d-flex justify-content-between align-items-center border rounded-3 p-3">
      <h2>Vrc Payment Method</h2>
      <div className="d-flex gap-2">
        <button
          className="btn btn-primary"
          onClick={() => setRefresh(!refresh)}
        >
          Refresh
        </button>
        <AddMethod
          showModal={showModal}
          setShowModal={setShowModal}
          formData={formData}
          setFormData={setFormData}
        />
      </div>
    </div>
  );
};

export default TopBar;
