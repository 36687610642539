import React, { useEffect, useState } from "react";
import { APIinstance } from "../../axios.config";
import ConfirmationModal from "../../common/modal/ConfirmationModal";
import { saveAs } from "file-saver";

const Profile = () => {
  const [bookings, setBookings] = useState([]);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await APIinstance.get("/api/bookings");
        if (response.data.success) {
          setBookings(response.data.data);
        } else {
          setError("Failed to fetch bookings");
        }
      } catch (err) {
        setError("An error occurred while fetching data");
      }
    };

    fetchBookings();
  }, []);

  const markAsContacted = async () => {
    if (!selectedBooking) return;

    try {
      const response = await APIinstance.get(
        `/api/bookings/${selectedBooking._id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        }
      );

      if (response.data.success) {
        setBookings((prevBookings) =>
          prevBookings.map((booking) =>
            booking._id === selectedBooking._id
              ? { ...booking, contacted: true }
              : booking
          )
        );
        setShowModal(false);
        setSelectedBooking(null);
      } else {
        alert("Failed to update booking status");
      }
    } catch (err) {
      console.error("Error updating contacted status:", err);
      alert("An error occurred while updating the booking status");
    }
  };

  const handleShowModal = (booking) => {
    setSelectedBooking(booking);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedBooking(null);
  };

  const exportData = async () => {
    try {
      const response = await APIinstance.post(
        "/api/bookings/export",
        {},
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("authToken"),
          },
        }
      );

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      saveAs(blob, "data.xlsx");
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">Booking List</h1>

      {error && <div className="alert alert-danger">{error}</div>}

      <button className="btn btn-primary" onClick={exportData}>
        exportData
      </button>
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>#</th>
            <th>Full Name</th>
            <th>Phone Number</th>
            <th>CNIC Number</th>
            <th>Plot</th>
            <th>Payment Type</th>
            <th>Transaction Hash</th>
            <th>Screenshot</th>
            <th>Total Payment</th>
            <th>Created At</th>
            <th>Contacted</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {bookings.length > 0 ? (
            bookings.map((booking, index) => (
              <tr key={booking._id}>
                <td>{index + 1}</td>
                <td>{booking.fullName || "-"}</td>
                <td>{booking.phoneNumber || "-"}</td>
                <td>{booking.cnicNumber || "-"}</td>
                <td>{booking.plot || "-"}</td>
                <td>{booking.paymentType || "-"}</td>
                <td>
                  {booking.transactionHash ? (
                    <a
                      href={`https://vrcscan.com/tx/${booking.transactionHash}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      title={booking.transactionHash}
                    >
                      {`${booking.transactionHash.substring(0, 10)}...`}
                    </a>
                  ) : (
                    "-"
                  )}
                </td>
                <td>
                  {booking.screenShot ? (
                    <a
                      href={`/${booking.screenShot}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Screenshot
                    </a>
                  ) : (
                    "-"
                  )}
                </td>
                <td>{booking.totalPayment || "-"}</td>
                <td>{new Date(booking.createdAt).toLocaleString()}</td>
                <td>{!!booking.contacted ? "Contacted" : "Not Contacted"}</td>
                <td>
                  {!!booking.contacted ? (
                    <button className="btn btn-secondary" disabled>
                      Contacted
                    </button>
                  ) : (
                    <button
                      className="btn btn-success"
                      onClick={() => handleShowModal(booking)}
                    >
                      Mark as Contacted
                    </button>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="12" className="text-center">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Confirmation Modal */}
      <ConfirmationModal
        showConfirmModal={showModal}
        closeConfirmModal={handleCloseModal}
        handleDelete={markAsContacted}
        modalTitle="Confirm Contact"
        message="Are you sure you want to mark it as contracted?"
        button="Confirm"
      />
    </div>
  );
};

export default Profile;
