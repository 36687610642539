import axios from "axios";

const url = process.env.REACT_APP_BASE_URL + "/api/vrc" || "";

const token = localStorage.getItem("authToken");

console.log("token", token);

export const postPayments = async (form) => {
  const postUrl = form._id ? `${url}/${form._id}` : `${url}/`;
  delete form._id;
  const response = await axios.post(postUrl, form, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response;
};

export const getPayments = async () => {
  const response = await axios.get(`${url}/`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response;
};

export const deletePayments = async (id) => {
  const response = await axios.delete(`${url}/${id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return response;
};
