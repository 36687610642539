import React from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { postPayments } from "../../../apis/vrcPayment";

const AddMethod = ({ showModal, setShowModal, formData, setFormData }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePaymentChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      payment_plans: {
        ...prevData.payment_plans,
        [name]: value,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      const response = await postPayments(formData);
      console.log("Response:", response);
      alert("Payment method added successfully");
    } catch (error) {
      alert(error.message);
    }
    setShowModal(false); // Close the modal after submission
  };

  return (
    <div>
      <Button variant="primary" onClick={() => setShowModal(true)}>
        Add Method
      </Button>

      <Modal
        size="xl"
        centered
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Payment Method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="marla">
                  <Form.Label className="fw-bold">Marla</Form.Label>
                  <Form.Control
                    type="number"
                    name="marla"
                    value={formData.marla}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="payment_plans.duration">
                  <Form.Label className="fw-bold">Duration (years)</Form.Label>
                  <Form.Control
                    type="text"
                    name="duration"
                    value={formData.payment_plans.duration}
                    onChange={handlePaymentChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="payment_plans.down_payment">
                  <Form.Label className="fw-bold">Down Payment</Form.Label>
                  <Form.Control
                    type="number"
                    name="down_payment"
                    value={formData.payment_plans.down_payment}
                    onChange={handlePaymentChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="payment_plans.confirmation">
                  <Form.Label className="fw-bold">Confirmation</Form.Label>
                  <Form.Control
                    type="number"
                    name="confirmation"
                    value={formData.payment_plans.confirmation}
                    onChange={handlePaymentChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="payment_plans.monthly_installment">
                  <Form.Label className="fw-bold">
                    Monthly Installment
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="monthly_installment"
                    value={formData.payment_plans.monthly_installment}
                    onChange={handlePaymentChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="payment_plans.semi_annual_installment">
                  <Form.Label className="fw-bold">
                    Semi-Annual Installment
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="semi_annual_installment"
                    value={formData.payment_plans.semi_annual_installment}
                    onChange={handlePaymentChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="payment_plans.possession">
                  <Form.Label className="fw-bold">Possession</Form.Label>
                  <Form.Control
                    type="number"
                    name="possession"
                    value={formData.payment_plans.possession}
                    onChange={handlePaymentChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="payment_plans.total_price">
                  <Form.Label className="fw-bold">Total Price</Form.Label>
                  <Form.Control
                    type="number"
                    name="total_price"
                    value={formData.payment_plans.total_price}
                    onChange={handlePaymentChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="d-flex align-items-center justify-content-center">
              <Button className="px-5" variant="primary" type="submit">
                {formData._id ? "Edit" : "Submit"}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddMethod;
