import React, { useEffect, useState } from "react";
import { deletePayments, getPayments } from "../../../apis/vrcPayment";
import moment from "moment";
import ConfirmationModal from "../../../common/modal/ConfirmationModal";

const ShowPayment = ({ setFormData, refresh, setShowModal }) => {
  const [payment, setPayment] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [paymentToDelete, setPaymentToDelete] = useState(null);

  const handleGetData = async () => {
    try {
      const response = await getPayments();
      setPayment(response.data.data);
    } catch (error) {
      alert(error.message);
    }
  };

  useEffect(() => {
    handleGetData();
  }, [refresh]);

  const handleEdit = (data) => {
    setFormData(data);
    setShowModal(true);
  };

  const handleDelete = async () => {
    if (!paymentToDelete) return;

    try {
      const response = await deletePayments(paymentToDelete._id);
      if (response.status === 200) {
        setShowConfirmModal(false); // Close modal after successful deletion
        handleGetData(); // Refresh payment data
      } else {
        alert("Failed to delete payment method");
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const openConfirmModal = (paymentData) => {
    setPaymentToDelete(paymentData); // Set payment data to be deleted
    setShowConfirmModal(true); // Show confirmation modal
  };

  const closeConfirmModal = () => {
    setShowConfirmModal(false); // Close modal without deleting
    setPaymentToDelete(null);
  };

  return (
    <div>
      <table className="table table-bordered table-striped table-responsive">
        <thead>
          <tr>
            <th>#</th>
            <th>Marla</th>
            <th>Duration</th>
            <th>Down Payment</th>
            <th>Confirmation</th>
            <th>Monthly Installment</th>
            <th>Semi-Annual Installment</th>
            <th>Possession</th>
            <th>Total Price</th>
            <th>Created At</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {payment.length > 0 ? (
            payment.map((data, index) => (
              <tr key={data._id}>
                <td>{index + 1}</td>
                <td>{data.marla || "-"}</td>
                <td>{data.payment_plans?.duration || "-"}</td>
                <td>{data.payment_plans?.down_payment || "-"}</td>
                <td>{data.payment_plans?.confirmation || "-"}</td>
                <td>{data.payment_plans?.monthly_installment || "-"}</td>
                <td>{data.payment_plans?.semi_annual_installment || "-"}</td>
                <td>{data.payment_plans?.possession || "-"}</td>
                <td>{data.payment_plans?.total_price || "-"}</td>
                <td>{moment(data.createdAt).format("DD MMM YYYY")}</td>
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleEdit(data)}
                  >
                    Edit
                  </button>
                </td>
                <td>
                  <button
                    className="btn btn-danger"
                    onClick={() => openConfirmModal(data)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="12" className="text-center">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Confirmation Modal */}
      <ConfirmationModal
        showConfirmModal={showConfirmModal}
        closeConfirmModal={closeConfirmModal}
        handleDelete={handleDelete}
        paymentToDelete={paymentToDelete} // Pass the payment data to delete
        modalTitle="Delete Payment"
        message="Do you really want to delete this payment plan?"
      />
    </div>
  );
};

export default ShowPayment;
