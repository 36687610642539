import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/login/Login";
import Home from "./components/home/Home";
import Profile from "./components/profile/Profile"; // Add another route example
import DashboardLayout from "./components/Dashboard/DashboardLayout";
import VrcPayment from "./components/vrcPayment/VrcPayment";
import UpdatePass from "./components/updatepass/UpdatePass";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public Route */}
        <Route path="/" element={<Login />} />
        {/* Protected Routes with Layout */}
        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route path="inquiry" element={<Home />} />
          <Route path="bookings" element={<Profile />} />
          <Route path="vrc-payment" element={<VrcPayment />} />
          <Route path="update-password" element={<UpdatePass />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
