import React from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmationModal = ({
  showConfirmModal,
  closeConfirmModal,
  handleDelete,
  paymentToDelete,
  modalTitle = "Confirm Deletion",
  message = "Are you sure you want to delete this payment method?",
  button = "Yes, Delete",
}) => {
  return (
    <Modal
      show={showConfirmModal}
      onHide={closeConfirmModal}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeConfirmModal}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={() => handleDelete(paymentToDelete?._id)}
        >
          {button}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
